
import Vue from "vue";
import { mapState } from "vuex";
import PostcodeCityList from "@/components/PostcodeCityList.vue";
import PostcodeDeleteDialog from "@/components/PostcodeDeleteDialog.vue";

export default Vue.extend({
  components: {
    PostcodeCityList,
    PostcodeDeleteDialog,
  },
  computed: {
    ...mapState(["data"]),
    showAddPostCode: {
      get(): boolean {
        return this.showAPC;
      },
      set(val: boolean) {
        if (!val) {
          this.showAPC = false;
          this.postcode = "";
        }
      },
    },
    showDeletePostcode: {
      get(): boolean {
        return this.showDPD;
      },
      set(val: boolean) {
        if (!val) {
          this.showDPD = false;
        }
      },
    },
    postcodeValid() {
      return /^[0-9]{5}$/.test(this.postcode);
    },
  },
  data: () => ({
    showAPC: false,
    showDPD: false,
    postcode: "",
    loading: false,
  }),
  methods: {
    async addPostcodeClick() {
      this.loading = true;
      await this.$store.dispatch("addPostcode", this.postcode);
      this.loading = false;
      this.showAddPostCode = false;
    },
  },
  beforeMount() {
    this.$store.dispatch("refreshPostcodes");
    this.$store.dispatch("refreshCities");
  },
});
