
import Vue from "vue";
import { mapActions } from "vuex";

export default Vue.extend({
  props: {
    postcode: {
      type: Object,
      required: true,
    },
  },
  computed: {
    showCityAddDialog: {
      get(): boolean {
        return this.showCAD;
      },
      set(value: boolean) {
        if (!value) {
          this.cityName = "";
          this.showCAD = false;
        }
      },
    },
  },
  data: () => ({
    cityName: "",
    showCAD: false,
    loading: false,
  }),
  methods: {
    ...mapActions(["addCity", "removeCity"]),
    async addCityClick() {
      this.loading = true;
      await this.addCity({ postcode: this.postcode, cityName: this.cityName });
      this.showCityAddDialog = false;
      this.loading = false;
    },
    async deleteFromPostcode(cityId: number) {
      await this.removeCity({ postcode: this.postcode, cityId });
    },
  },
});
