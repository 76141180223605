
import Vue from "vue";
import { mapActions, mapState } from "vuex";

export default Vue.extend({
  props: {
    show: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapState(["data"]),
    showDialog: {
      get(): boolean {
        return this.show;
      },
      set(val: boolean) {
        if (!val) {
          this.$emit("close");
        }
      },
    },
  },
  data: () => ({
    postcodeId: 0,
    loading: false,
  }),
  methods: {
    ...mapActions(["deletePostcode"]),
    async deletePostcodeClick() {
      this.loading = true;
      await this.deletePostcode(this.postcodeId);
      this.loading = false;
      this.showDialog = false;
    },
  },
});
